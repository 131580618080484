@import "../../Global.scss";

.certificates {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: row;
  .arrow {
    height: 70px;
    font-size: 70px;
    position: absolute;

    @include mobile {
      display: none;
    }

    &.left {
      left: 25%;
      cursor: pointer;
    }

    &.right {
      right: 25%;
      cursor: pointer;
    }
  }

  h1 {
    position: absolute;
    z-index: 2;
    top: 0;
    font-size: 30px;

    @include mobile {
      z-index: 0;
    }
  }

  .slider {
    height: 95%;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;

    @include mobile {
      width: 100%;
      margin-top: 25px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }

    .container {
      width: 100vw;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include mobile {
        width: 45%;
        height: 100%;
        max-width: 311px;
        max-height: 448px;
        display: grid;
        grid-template-rows: 2fr;

        
      }

      .item {
        width: 400px;
        height: 100%;
        background-color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @include mobile {
          width: 90%;
          height: 86%;
        }

        .left {
          flex: 4;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          @include mobile {
            flex: 5;
          }

          .leftContainer {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include mobile {
              width: 100%;
              height: 100%;
            }

            .source {
              display: flex;
              flex-direction: row;
              justify-content: space-around;

              @include mobile {
                text-align: center;
              }

              .link {
                text-decoration: none;
                color: white;
                background-color: $mainColor;
                padding: 5px 10px;
                border-radius: 5px;

                @include mobile {
                  padding: 5px 5px;
                  margin: 0 5px;
                  font-size: 10px;
                }
              }
            }

            .imgContainer {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;

              @include mobile {
                width: 100%;
                height: 100%;
              }

              a {
                overflow: hidden;
                border-radius: 20px;
                height: 87%;

                @include mobile {
                  height: auto;
                }
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                @include mobile {
                }
              }
            }

            h2 {
              font-size: 20px;

              @include mobile {
                font-size: 13px;
              }
            }

            p {
              font-size: 13px;
              @include mobile {
                display: none;
              }
            }

            span {
              font-size: 12px;
              font-weight: 600;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }

        .right {
          flex: 8;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            width: 400px;
            transform: rotate(-10deg);
            overflow: hidden;
          }
        }
      }
    }
  }
}
