@import "../../Global.scss";

.works {
  background-color: rgb(84, 195, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .arrow {
    height: 50px;
    position: absolute;

    @include mobile {
      display: none;
    }

    &.left {
      left: 0;
      transform: rotateY(180deg);
      cursor: pointer;
    }

    &.right {
      right: 100px;
      cursor: pointer;
    }
  }

  .slider {
    height: 350px;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;

    @include mobile {
      height: 100vh;
      flex-direction: column;
      justify-content: center;
    }

    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;

      .item {
        width: 700px;
        height: 100%;
        background-color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @include mobile {
          width: 80%;
          height: 150px;
          margin: 15px 0;
        }

        .left {
          flex: 4;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;

          @include mobile {
            flex: 5;
          }

          .leftContainer {
            width: 90%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include mobile {
              width: 100%;
              height: 100%;
              padding-left: 10px;
            }

            .source {
              display: flex;
              flex-direction: row;
              justify-content: space-around;

              @include mobile{
                text-align: center;
              }

              .link {
                text-decoration: none;
                color: white;
                background-color: $mainColor;
                padding: 5px 10px;
                border-radius: 5px;

                @include mobile {
                  padding: 5px 5px;
                  margin: 0 5px;
                  font-size: 10px;
                }
              }
            }

            .imgContainer {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: rgb(245, 179, 155);
              display: flex;
              align-items: center;
              justify-content: center;

              @include mobile {
                width: 20px;
                height: 20px;
              }

              .img {
                width: 25px;
                @include mobile {
                  width: 15px;
                }
              }
            }

            h2 {
              font-size: 20px;

              @include mobile {
                font-size: 13px;
              }
            }

            p {
              font-size: 13px;
              @include mobile {
                display: none;
              }
            }

            span {
              font-size: 12px;
              font-weight: 600;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }

        .right {
          flex: 8;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            width: 400px;
            transform: rotate(-10deg);
            overflow: hidden;
          }
        }
      }
    }
  }
}
