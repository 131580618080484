@import '../../Global.scss';

.tech {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  h1 {
    width: 100vw;
    text-align: center;
    margin-bottom: 30px;

    @include mobile {
      height: 20%;
    }
  }

  .container {
    width: 50%;
    margin: 0 auto;
    .list {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      @include mobile {
        padding-left: unset;
      }

      li {
        img {
          margin: 8px;
        }
      }

      p {
        text-align: center;
        font-size: 20px;
      }
    }
  }
}
